import moment from 'moment';

export function formatDate(value, format = 'YYYY-MM-DD', defaultValue = '-') {
  if (!value) return defaultValue;
  return moment(value).utc().format(format);
}

export function formatDatetime(value, defaultValue = '-') {
  return formatDate(value, 'YYYY-MM-DD HH:mm:ss', defaultValue);
}

export function formatValue(value) {
  const number = Number.parseFloat(value);
  if (Number.isNaN(number)) {
    return value;
  }

  return number.toFixed(2);
}
