export const REVISION_TAG_STATEMENTS_CONFIGURATIONS = 'statements_configurations';
export const DEFAULT_CLEARING_HOUSE = 'waystar';
export const PRE_DETERMINED_EMPLOYEE_COUNT = 'pre_determined_employee_count';
export const CURRENCIES = [ 'USD', 'CAD', 'GBP', 'EUR', 'AUD' ];
export const BILLING_CONFIG_STATUS_DRAFT = 'draft';
export const BILLING_CONFIG_STATUS_NEW = 'new';

export const DEFAULT_PROVIDER_ID = 5;
export const PEPM_BILLING_MODEL_ID = 9;
export const STANDARD_1_3RD_BILLING_MODEL_ID = 1;
export const MOVE_BILLING_MODEL_ID = 10;
export const SOLERA_BILLING_MODEL_ID = 11;
export const HIGHMARK_BILLING_MODEL_ID = 12;
export const PLATFORM_BILLING_MODEL_ID = 13;
export const RULE_40_PREDICTED = 40;
export const RULE_41_1ST_MILESTONE = 41;
export const RULE_44_1ST_SESSION_Y2 = 44;

export const RULES_3RD_SESSIONS_REQUIRED = [ 38, 42 ];
export const RULES_9TH_SESSIONS_REQUIRED = [ 39, 43 ];
export const DEFAULT_3RD_9TH_BILLING_MODELS = [
  STANDARD_1_3RD_BILLING_MODEL_ID,
  SOLERA_BILLING_MODEL_ID,
  HIGHMARK_BILLING_MODEL_ID,
];

export const TRANSACTION_TYPES = [
  'ENROLLMENT_FEE',
  'SESSION_FEE',
  '3_SESSION_FEE',
  '9_SESSION_FEE',
  'FULL_FEE',
  '1ST_MILESTONE',
  '2ND_MILESTONE',
  '3RD_MILESTONE',
  'PAIN_SCORE_REDUCTION_FEE',
  'TREATMENT_COMPLETION',
];

export const UNITS = {
  SWORD: 'sword',
  BLOOM: 'bloom',
  MOVE: 'move',
};

export const UNIT_OPTIONS = Object.values(UNITS);

export const APPLICABLE_TO = [
  '837 file',
  'External Claims Report',
];

export const INVOICE_STATUS = {
  PENDING: 'pending',
  AWAITING_PROCESSING: 'awaiting_processing',
  PROCESSING: 'processing',
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
  FAILED: 'failed',
};

export const CLAIM_ASSIGNEES = [
  'Christina Kaldi',
  'Erin Holsted',
  'Rachana Krishnan',
  'Sierra Palmquist',
];
