<template>
  <div class="container-fluid p-4">
    <b-row class="mb-4">
      <b-col class="d-flex"><h4>Claim Tasks</h4></b-col>
    </b-row>
    <b-card no-body>
      <div class="d-flex">
        <nav class="left-nav">
          <router-link v-for="menu in menus" :key="menu.route" :to="`/finance-operations${menu.route}`">
            <div :class="['item mt-2', {'item-selected': isActive(menu.route)}]">
              {{ menu.title }}
            </div>
          </router-link>
        </nav>
        <b-container class="mt-4" fluid style="overflow-x: auto;">
          <slot/>
        </b-container>
      </div>
    </b-card>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'ClaimTasksLayout',
  data() {
    return {
      menus: [
        {
          title: translations.finance_operations.claim_accounts.nav_title,
          route: '/claim-accounts',
        },
        {
          title: translations.finance_operations.claims_follow_up_tasks.nav_title,
          route: '/claim-follow-up-tasks',
        },
        {
          title: translations.finance_operations.claim_write_off_tasks.nav_title,
          route: '/claim-write-off-tasks',
        },
      ],
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>
<style scoped>
.left-nav {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  background-color: #e7eaf0;
  white-space: nowrap;

  .item {
    padding: 0.5rem 1rem;
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .item-selected {
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
  }
}
</style>
