<template>
      <div class="d-flex align-items-center">
      <b-form-group :label="translations.assignee" class="mr-4">
        <multiselect
        v-model="filters.assignees"
        :disabled="loading"
        :options="filter_options.assignees"
        :multiple="true"
        :showLabels="false"
        label="text"
        track-by="value"
        @select="check"/>
      </b-form-group>
      <b-button
        data-testid="search-button"
        variant="primary"
        class="mt-2"
        @click="handleSearch">
        {{ translations.search }}
      </b-button>
    </div>
</template>

<script>
import translations from '@/translations';
import Multiselect from 'vue-multiselect';
import { CLAIM_ASSIGNEES } from '@/constants/finance';

export default {
  name: 'ClaimAccountsFilters',
  components: {
    Multiselect,
  },
  data() {
    return {
      translations: translations.finance_operations.claim_accounts.filters,
      loading: false,
      filters: {
        assignees: [],
      },
      filter_options: {
        assignees: [],
      },
    };
  },
  beforeMount() {
    this.formatAssigneeOptions();
  },
  methods: {
    formatAssigneeOptions() {
      const claimAssignees = CLAIM_ASSIGNEES.map(claimAssign => ({
        value: claimAssign,
        text: claimAssign,
      }));

      this.filters.assignees = [
        { value: '', text: this.translations.default_assignee_filter },
      ];
      this.filter_options.assignees = [
        { value: '', text: this.translations.default_assignee_filter },
        { value: 'unassigned', text: this.translations.blank_option_assignee_filter },
        ...claimAssignees,
      ];
    },
    check(assignee) {
      if (assignee.value === '') {
        this.filters.assignees = [ { value: '', text: this.translations.default_assignee_filter } ];
      } else if (this.filters.assignees[0].value === '') {
        this.filters.assignees.splice(0, 1);
      }
    },
    handleSearch() {
      this.loading = true;
      const filtersToSend = {};

      const assignees = [];
      if (this.filters.assignees?.length > 0) {
        this.filters.assignees.forEach(assignee => {
          if (assignee.value !== '') {
            assignees.push(assignee.value);
          }
        });
      }
      filtersToSend.assignees = assignees;

      this.$emit('apply-filters', filtersToSend);
      this.loading = false;
    },
  },
};
</script>
